// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDk4nmyotZ55l9qETbsZLKYMg_wG-WOuOY",
    authDomain: "best-peoples.firebaseapp.com",
    projectId: "best-peoples",
    storageBucket: "best-peoples.appspot.com",
    messagingSenderId: "865499179391",
    appId: "1:865499179391:web:d15cdd04a363cd666b524a",
    measurementId: "G-1TEY8Q7QDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const isLocalHost = window.location.href.includes('localhost');

export function createAnalyticsEvent({eventName, eventParams}){
    if(isLocalHost) return;
    try{
        window.clarity("event", eventName)
    }
    catch (e){
        console.error('window.clarity error: ',e);
    }
    logEvent(analytics, eventName, eventParams);
}