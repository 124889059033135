const storage = window.localStorage;

const ITEMS = {
    favoritesObj: 'favoritesObj',
    sendReviewMap: 'sendReviewMap',
}

function read(key) {
    return storage.getItem(key);
}

function save(key, val) {
    return storage.setItem(key, val);
}

// function remove(key) {
//     return storage.removeItem(key);
// }


export function addToFavorites({id}) {
    let oldFavorites = read(ITEMS.favoritesObj);
    if(!oldFavorites){
        save(ITEMS.favoritesObj, JSON.stringify({[id]: true}));
        return;
    }
    oldFavorites = JSON.parse(oldFavorites);
    oldFavorites[id] = true;
    save(ITEMS.favoritesObj, JSON.stringify(oldFavorites));
}

export function removeFromFavorites({id}) {
    let oldFavorites = read(ITEMS.favoritesObj);
    if(!oldFavorites){
        return;
    }
    oldFavorites = JSON.parse(oldFavorites);
    delete oldFavorites[id];
    save(ITEMS.favoritesObj, JSON.stringify(oldFavorites));
}

export function isFavorite({id}) {
    let currentFavorites = read(ITEMS.favoritesObj);
    if(!currentFavorites){
        return false;
    }
    currentFavorites = JSON.parse(currentFavorites);
    return !!currentFavorites[id]
}

export function getFavorites() {
    let currentFavorites = read(ITEMS.favoritesObj);
    if(!currentFavorites){
        return null;
    }
    return Object.keys(JSON.parse(currentFavorites));
}


export function addToSendReviewMap({id}) {
    let oldSendReviewMap = read(ITEMS.sendReviewMap);
    if(!oldSendReviewMap){
        save(ITEMS.sendReviewMap, JSON.stringify({[id]: true}));
        return;
    }
    oldSendReviewMap = JSON.parse(oldSendReviewMap);
    oldSendReviewMap[id] = true;
    save(ITEMS.favoritesObj, JSON.stringify(oldSendReviewMap));
}


export function isSendReview({id}) {
    let currentReviewMap = read(ITEMS.sendReviewMap);
    if(!currentReviewMap){
        return false;
    }
    currentReviewMap = JSON.parse(currentReviewMap);
    return !!currentReviewMap[id];
}