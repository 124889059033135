import React from "react";
import './favorites.scss';
import Loading from "../Loading/loading";
import {getFavorites} from '../../store/localStorageMgr';
import { useHistory } from 'react-router-dom';
import ProfessionalsList from "../ProfessionalsList/professionalsList";
import {ReactComponent as StarIcon} from '../../assests/star-icon.svg';
import { getLogicalName } from '../../utils';


export default function Favorites(props) {
    const history = useHistory();

    const {loading, professionals} = props;

    if(loading) return <Loading/>

    const favorites = getFavorites() || [];

    const favoritesProfessionals = favorites.length > 0 ? professionals.filter(professional=>favorites.includes(professional.id)) : [];

    const logicName = getLogicalName();

    return (
            <div className={'favorites-container'} >
                <div className={'favorites-page-header'}>
                    <a onClick={()=>history.push(`/${logicName}`)} href={'#'}>המומלצים</a>
                    {' > '}
                    מועדפים
                </div>
                <div className={'favorites-title'}>
                    <div>מועדפים</div>
                </div>
                {favoritesProfessionals.length > 0 ?
                    <ProfessionalsList loading={loading} professionals={favoritesProfessionals}/>:
                    <div className={'no-favorites-container'}>
                        <div className={'no-favorites-main'}>אין בעלי עסק שמורים.</div>
                        <div className={'no-favorites-sub-main'}>בשביל להוסיף
                        <a onClick={()=>history.push(`/${logicName}/home/welcome/הכל`)} href={'#'}> אנשי מקצוע </a>
                            למועדפים, לחץ על כפתור:
                            <div className={'button-link-container'}>
                                <div className={'button-links-circle'}><StarIcon/></div>
                                <div className={'button-link-text'}>הוסף למועדפים</div>
                            </div>
                            בתחתית העמוד של בית העסק הנבחר.
                        </div>

                    </div>
                }
            </div>
    );
}