import React, {useEffect, useState} from 'react';
import './firebase/firebase';
import './App.css';

import SingletoneStoreClass from "./store/store";
import ProfessionalsList from './componenets/ProfessionalsList/professionalsList';
import SearchAndFilters from './componenets/SearchAndFilters/searchAndFilters';
import ProfessionalPage from './componenets/ProfessionalPage/professionalPage';
import Header from './componenets/Header/header';
import Bottom from './componenets/Bottom/bottom';
import Info from './componenets/Info/info';
import Favorites from './componenets/Favorites/favorites';
import PreLoad from './componenets/PreLoad/preLoad';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { getLogicalName } from './utils';
import ChooseTenant from "./componenets/ChooseTenant/ChooseTenant";
import config from "./config/config";


const ALL = 'הכל';

const store = SingletoneStoreClass.getInstance();

function App() {

    const logicName = getLogicalName();


    const [professionals, setProfessionals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [categories, setCategories] = useState();
    const [filterCategory, setFilterCategory] = useState({});
    const [hasError, setFasError] = useState(false);


    useEffect( () => {
        const getData = async ()=>{
            try{
                await store.getData();
                setProfessionals(store.professionals);
                setCategories([].concat([ALL],store.categories));
                setFasError(false);
            }catch (e){
                setFasError(true);
                throw e;
            }finally {
                setLoading(false);
            }
        }
        if(logicName) getData();

    }, []);

    useEffect( () => {
        if(searchText){
            setLoading(true);
            setTimeout(()=>setLoading(false), 500);
        }
    }, [searchText]);




    const filterProfessionals = ()=> {
        if(!searchText && !filterCategory) return [];

        let result = [...professionals];

        if(searchText) {
            result = result.filter(professional=>`${professional.id} ${professional.category} ${professional.businessName} ${professional.ownerName} ${professional.services} 0${professional.phoneNumber} ${professional.searchKeywords}`.includes(searchText))
        }

        if(filterCategory === ALL){
            return result;
        }

        if(filterCategory){
            result = result.filter(professional=>filterCategory === professional.category);
        }

        return result;
    }

    const filteredProfessionals = filterProfessionals();

    return (
    <div className="App">
      <div className={'app-body'}>
          <BrowserRouter>
              <Header/>
              <Switch>
                  <Route exact path="/choose-tenant" render={({match})=><ChooseTenant />}/>
                  <Redirect exact from="/" to="/community" />
                  <Redirect exact from="/:tenant" to="/:tenant/home/welcome" />
                  <Route path="/:tenant/info" render={({match})=><Info loading={loading} professionalsLength={professionals.length}/>}/>
                  <Route path="/:tenant/favorites" render={({match})=><Favorites loading={loading} professionals={professionals}/> }/>
                  <Route exact path="/:tenant/:id" render={({match})=><ProfessionalPage loading={loading} id={match.params.id} professionals={professionals}/>}/>
                  <Route path="/:tenant/home/welcome">
                      <div className={'app-header'}>
                          <SearchAndFilters
                              totalLength={filteredProfessionals.length}
                              searchText={searchText}
                              setSearchText={setSearchText}
                              categories={categories}
                              filterCategory={filterCategory}
                              setFilterCategory={setFilterCategory}
                              professionals={filteredProfessionals}
                          />
                      </div>
                      <ProfessionalsList loading={loading} professionals={filteredProfessionals} hasError={hasError}/>
                  </Route>
                  {/*<Route exact path="/" render={({match})=>logicName? <Redirect to={`/${logicName}`}/> : <Redirect to={'/community'}/>}/>*/}

              </Switch>
              <Bottom/>
          </BrowserRouter>
      </div>
        <Bottom/>
        <PreLoad professionals={professionals}/>
    </div>
  );
}

export default App;
