import React, { useState } from "react";

import './header.scss';
import { useHistory } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import Menu  from '../Menu/menu';
import config from '../../config/config';
import { getLogicalName } from '../../utils';

export default function Header() {

    const [isOpen, setOpen] = useState(null)

    const history = useHistory();

    const logicName = getLogicalName();
    if (!logicName) return null;

    const onHeaderClicked = ()=>{
        history.push(`/${logicName}`);
    }

    const onClickOutsideTheMenu = ()=> {
        if(!isOpen) return;
        setOpen(false);

    }

    return (
        <div>
            <div className={'app-header-container'}>
                <div className={'app-header-right'} onClick={onHeaderClicked}>
                    <div>{config.title}</div>
                    <img className={'logo-header'} src={config.wideIcon} alt={''}/>
                </div>
                <div className={'app-header-left'}>
                    <Hamburger className={'hamburger-icon'} size={20} toggled={isOpen} toggle={setOpen} />
                </div>
            </div>
            {isOpen ? <React.Fragment>
                <div className={`app-container ${isOpen ? 'shadow' : ''}`}></div>
                <div className={`app-container-no-menu-side-container`} onClick={onClickOutsideTheMenu}></div>
                <div className={`menu-side-container ${isOpen ? 'open' : (isOpen === null ? '' : 'close')}`}>
                    <Menu setOpen={setOpen}/>
                </div>
            </React.Fragment>: null}
        </div>

    );
}