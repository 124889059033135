import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if('serviceWorker' in navigator){
    console.log("serviceWorker available for this browser.");
    navigator.serviceWorker.register(`${window.location.origin}/sw.js`, ).then(()=>{
        console.log("Service worker registered!");
    });
}else{
    console.log("serviceWorker does't available for this browser.");
}
