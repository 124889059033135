import './preload.scss';

export default function PreLoad(props) {

    const {professionals} = props;
    return (
        <div className={'preload-logs-container'}>
            {professionals.map(p=>p.logoUrl ? <img src={p.logoUrl} /> : null)}
        </div>
    );
}