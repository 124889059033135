import React, {useEffect, useState} from 'react';

// import {ReactComponent as BackIcon} from '../../assests/back-icon.svg';
import BackIcon from '../../assests/arrow_back.svg';
import './searchAndFilters.scss';
import getIcon from '../../config/iconToCategoryMap';
import {Route, Switch, useHistory} from 'react-router-dom';
import {createAnalyticsEvent} from '../../firebase/firebase'
import {getLogicalName} from "../../utils";
import add_yellow_circle_logo from "../../assests/add_yellow_circle.svg";
import config from "../../config/config";

const categorySort = (categories)=>{


    const categoryMap = {
        'הכל': 100000,
        'גיוס משאבים': 100,
        'עיצוב גרפי, מיתוג ומתנות': 90,
        'שיווק דיגיטלי לעמותות': 80,
        'אוכל': 70,
        'אירועים': 60,
        'סדנאות והפעלות': 50,
        'ייעוץ פיננסי לעמותות': 40,
        'חינוך': 30,
        'טיפול ורפואה': 20,
        'ייעוץ משפטי לעמותות': 10,
        'סוכני ביטוח לעמותות': 5
    }



    return categories.sort((a,b)=>{
        let x = categoryMap[a] || 0;
        let y = categoryMap[b] || 0;
        return y - x;
    });
}


export default function SearchAndFilters(props) {
    const history = useHistory();

    const {totalLength, searchText, setSearchText, categories = [], setFilterCategory, professionals, filterCategory} = props;
    const logicName = getLogicalName();

    const onSearchTextChanged = (event)=>{
        setSearchText(event.target.value);
        createAnalyticsEvent({eventName: 'search', eventParams: {text: event.target.value}});

    }

    const onCategoryClicked = (category)=>{
        if(!category){
            console.error('[onCategoryClicked] but category is empty!');
            return;
        }

        createAnalyticsEvent({eventName: 'category-clicked', eventParams: {name: category}});

        setFilterCategory(category);



        history.push(`/${logicName}/home/welcome/${category}`);
    }

    return (
        <div className={'search-and-filters-container'}>
            {totalLength ? <div className={'top-header-search-and-filters'}>
                <div className={'back-container'} onClick={()=> {
                    setSearchText('')
                    history.push(`/${logicName}/home/welcome`)}
                }>
                    <img src={BackIcon}/>
                    <span>הכל</span>
                </div>
                <div className={'total-results'}>{totalLength} עסקים </div>
            </div> : null}
            <input className={'search-input'} value={searchText} onChange={onSearchTextChanged} placeholder={'חיפוש חופשי: שם / מקצוע'} />
            {totalLength ? null :
                (searchText ?
                    (filterCategory ? <div className={'no-results-where-found'}><span>לא נמצאו תוצאות בקטגוריית {filterCategory}</span></div> :
                        <div className={'no-results-where-found'}><span>ללא נמצאו תוצאות, נסו חיפוש אחר</span></div>)
                    : <div className={'no-results'}><span>הקלד על החיפוש או בחר קטגוריות</span></div>)}

            <Switch>
                <Route path="/:tenant/home/welcome" exact render={({match})=>{
                    setFilterCategory(null);
                    let categoriesToRender = categorySort(categories);
                    if(searchText){
                        const map = {};
                        professionals.forEach(professional=>{
                            if(!map[professional.category]) map[professional.category] = true;
                        });
                        categoriesToRender = Object.keys(map);
                    }
                    if(categoriesToRender.length === 0) return null;
                    return  <React.Fragment>
                        {/*<div className={'all-button'}>הכל</div>*/}
                        <div className={'categories-filter-container'}>
                            {categoriesToRender.map(category=>{
                                return <div className={'button-category'}
                                            onClick={()=>onCategoryClicked(category)}
                                            id={category}
                                            key={`category-${category}`}>
                                    {getIcon(category)}
                                    <div className={'button-category-name'}>{category}</div>
                                </div>
                            })}
                        </div>
                        {config.googleFormAddTenantUrl ? <div className={'add-item-button'} onClick={()=>{
                            history.push(`/${logicName}/signup`);
                            window.open(config.googleFormAddTenantUrl, '_blank').focus();
                        }}>
                            <img src={add_yellow_circle_logo} alt="icon"/>
                        </div> : null}
                    </React.Fragment>
                }}/>

                <Route path="/:tenant/home/welcome/:category" render={({match})=>{
                    const { category } = match.params;
                    if(categories.length > 0 && !categories.includes(category)){
                        history.push(`/${logicName}home/welcome`);
                        return;
                    }
                    setFilterCategory(category);
                    // return  <div className={'category-chosen-container'}>
                    //     {getIcon(category)}
                    //     <div className={'category-chosen-name'}>{category}</div>
                    //     <BackIcon className={'back-item'} onClick={()=>{
                    //         history.push(`/${logicName}/home/welcome`);
                    //     }}/>
                    // </div>
                }}/>
            </Switch>
        </div>
    );
}