import React, {useEffect, useState} from "react";
import './professionalsList.scss';
import ProfessionalItem from './ProfessionalItem/professionalItem'
import Loading from '../Loading/loading';
import Error from '../Error/error';

export default function ProfessionalsList(props) {

    const [ loadingList, setLoadingList ] = useState(props.loading);
    const { professionals, hasError } = props;

    useEffect( () => {
        setLoadingList(props.loading)
    }, [props.loading]);

    if(hasError) return <Error/>;

    return (
        <React.Fragment>
            {loadingList ? <Loading/> : null}
            <div className={`professionals-container ${loadingList ? 'loading' : ''}`}>
                {professionals.map(professional=><ProfessionalItem professional={professional} key={professional.id}/>)}
            </div>
        </React.Fragment>
    );
}