import maarava_wide_icon from "../assests/maarava_wide_icon.jpg";
import maarave_square_logo from "../assests/maarave-main-logo.jpg";
import my_gdud_wide_logo from "../assests/8114-wide-icon.png";
import my_gdud_square_logo from "../assests/8114-main-logo.jpeg";
import kiryat_netafim_wide_logo from "../assests/kiryat-netafim-wide.png";
import kiryat_netafim_square_logo from "../assests/kiryat-netafim-main.png";
import mimshak_wide_logo from "../assests/mimshak_wide_logo.png";
import mimshak_main_logo from "../assests/mimshak_main_logo.jpeg";
import community_main_logo from "../assests/mumlazim-wide-logo.png";
import community_wide_logo from "../assests/mumlazim-wide-logo.svg";

const dynamicConfig = {
    maarava: {
        googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT2ol9QWiw26yK6FFSJ-WiRl1WDw8cBJiET4M81YPEgbIQoq2Fk3RnL_dRutLRsEDymbqOAo3K5Osr4/pub?gid=0&single=true',
        title: 'המקצוענים של גוש מערבא!',
        addPeoplesPhone: '+972525406220',
        wideIcon: maarava_wide_icon,
        squareIcon: maarave_square_logo,
        appInfo: ({professionalsLength, logicName, history})=> <div className={'info-text'}>
            מי אנחנו?
            <br/>
            ״המקצוענים של מערבא״ הינה יוזמה מקומית של קהילת העסקים מערבא.
            תכנון ותחזוק האפליקציה נעשה בהתנדבות, במטרה לעודד צריכה מקומית  ולהנגיש לתושבים את המידע על העסקים המקומיים.
            <br/><br/>

            החזון שלנו:
            <br/>
            אנחנו מאמינים בכח של קהילה לחבור יחד ולפעול למען הצרכים שלה. בשנים האחרונות קמה קבוצת אשכול העסקים מערבא. הקבוצה כוללת פעילות בוואצאפ ומפגשי נטוורקינג עם הרצאות אחת לחודש.

            אחד הצרכים המרכזיים שעלו בקבוצה ומצד התושבים זה הצורך להנגיש את העסקים המקומיים לקהילת התושבים בגוש מערבא.
            בהתאם, כל בעל עסק מהישובים ברוכין, לשם, עלי זהב ופדואל יכול להופיע באופן שווה וללא תשלום במאגר המידע ״המקצוענים של מערבא״.
            <br/>
            <b>יחד אנחנו קהילה חזקה יותר!</b>

            <br/><br/>

            <div className={'info-text'}>
                עד כה הצטרפו למאגר {professionalsLength} בעלי עסקים שונים בגוש מערבא.
            </div>

            <br/><br/>

            צוות תכנון האפליקציה:
            <br/>
            יהודה לבנוני:  בניה ותחזוקת האפליקצייה, תושב לשם.
            <br/>
            <a onClick={()=>history.push(`/${logicName}/sindi-g-r`)} href={'#'}>סינדי  רימון</a>
            :בעלת 2 תארים בחשבונאות וכלכלה, תכנון ותפעול מאגר הנתונים, תושבת עלי זהב, בעלת עסק לשירותי חשבונאות ומיסים.
            <br/>
            <a onClick={()=>history.push(`/${logicName}/asl-serv`)} href={'#'}>אריה שוחט</a>
            : חשמלאי מהנדס, תכנון ותפעול מאגר הנתונים, תושב עלי זהב, בעל עסק לשירותי תיקונים למוצרי חשמל ביתיים.
            <br/>
            <a onClick={()=>history.push(`/${logicName}/orit-nagar`)} href={'#'}>אורית נגר</a>
            : בעלת תואר ראשון בעבודה סוציאלית קהילתית, מובילת אשכול העסקים מערבא, תושבת לשם. בעלת עסק לאיפור ושיער לכלות.
            <br/>
        </div>
    },
    8114: {
        // googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ1QMU-Ex5PrMUzLqp4WfeOUl6i-3duyuMjSXAU_RMblK8S70oVccvpsBr4K0WEGeNGJEiGZzERcTjT/pub?gid=0&single=true',
        googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSMHP8QqvgtjeWjBr4tFd_LVH69rTkIhOfYf2GmtiDLf5M3tTyAJYMkkxufnhA7xUnqTH4Ehz8bqCwz/pubhtml',

        // title: 'המקצוענים של 8114',
        addPeoplesPhone: '+972527909138',
        wideIcon: my_gdud_wide_logo,
        squareIcon: my_gdud_square_logo,
        googleFormAddTenantUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSezjYYmpLxhv-QMYXJHp3BhnS4LcbKY5QvHxhfpD-xJzzMxlQ/viewform?usp=sf_link',
        appInfo: ({professionalsLength, logicName, history})=> <div className={'info-container'} >
            <div className={'info-page-header'}>
                <a onClick={()=>history.push(`/${logicName}`)} href={'#'}>המקצוענים של 8114</a>
                {' > '}
                אודות האפליקצייה
            </div>
            <div className={'info-text'}>
                מי אנחנו?
                <br/>
                ״המקצוענים של 8114״ הינה יוזמה של אנשי גדוד מילואים 8114.
                <br/><br/>

                החזון שלנו:
                <br/>
                החברות והערבות ההדדית שאנחנו מפגינים אחד כלפי השני כשאנחנו נפגשים במילואים, לא מסתיימת כשמזדכים על הציוד ונוסעים הביתה.
                <br/>
                יצרנו פה הזדמנות להמשיך ולסייע אחד לשני ב"אזרחות", בשגרת היומיום, בעבודה, בשמחות ובימים פחות מוצלחים. יש לנו אוסף נדיר של אנשים טובים שתמיד ישמחו לעזור.
                מזמין את כולם לתרום ולהיתרם!
                <br/>
                <br/>

                <br/>
                <b>יחד אנחנו גדוד חזק יותר!</b>

                <br/><br/>
                <div className={'info-text'}>
                    עד כה הצטרפו למאגר {professionalsLength} בעלי עסקים שונים בגדוד 8114.
                </div>
                <br/>
                <br/>
                ניר סויסה מג״ד 8114 .
            </div>
        </div>
    },
    'kiryat-netafim': {
        googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQLyw9XMKWKVkH_yoUDqsk874Jb-RV9YkoSOhkSjAgVcH6QVa9WdSkv74SNh_TPL6LdCElL3mEZ5W36/pub?gid=0&single=true',
        title: 'המקצוענים של קרית נטפים',
        addPeoplesPhone: '+972546791586',
        wideIcon: kiryat_netafim_wide_logo,
        squareIcon: kiryat_netafim_square_logo,
        appInfo: ({professionalsLength, logicName, history})=> <div className={'info-container'} >
            <div className={'info-text'}>
                עד כה הצטרפו למאגר {professionalsLength} בעלי עסקים שונים בקרית נטפים.
            </div>
        </div>
    },
    mimshak: {
        googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR2UqoXX8rgQUT2tMkvy1M-xhSsitSCeoW1wpCQsGtbUkN59Kwv07V5w7wPxl6_eKYf6TYWtL4v-iDo/pub?gid=0&single=true',
        title: 'האגודה הישראלית לאקולוגיה ומדעי הסביבה',
        addPeoplesPhone: '+972527909138',
        wideIcon: mimshak_wide_logo,
        squareIcon: mimshak_main_logo,
        appInfo: ({professionalsLength, logicName, history})=> <div className={'info-container'} >
            <div className={'info-text'}>
                ממשק מכשירה בוגרות ובוגרי תואר שלישי הפועלים כיועצים במשרדי הממשלה ומסייעים בקבלת החלטות ובעיצוב מדיניות הסביבה בישראל
                עד כה הצטרפו למאגר {professionalsLength} מדעניות ומדענים.
            </div>
        </div>
    },
    community: {
        // googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ9eZWPoRQhIjs2dnmC9LVncSIDPoY49t7ZKX-2pknIkPOUlSUWlC00ZB32yERTbQbrj_HWRVnPVM5i/pub?gid=0&single=true',
        googleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTZYKcOmdcjV2nnC10enb7BX2NulleOU8JKAq7pZCYUSE7dADgfU8ne0dpOglCuCrnVIqBry6Ql71rC/pubhtml',
        // title: 'המקצוענים של העמותות',
        addPeoplesPhone: '+972543331373',
        wideIcon: community_wide_logo,
        squareIcon: community_main_logo,
        googleFormAddTenantUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSf0ngYn-oReCC4M6SwGM3PrEgHSIbp0FAUyQASxUEBSWaxw1g/viewform?usp=sf_link',
        appInfo: ({professionalsLength, logicName, history})=> <div className={'info-container'} >
            <div className={'info-text'}>אוסף בעלי המקצוע שיעזרו לקהילה שלכם.
                <br/><br/>
                עד כה הצטרפו למאגר {professionalsLength} בעלי עסקים.
            </div>
        </div>
    }
}

export default dynamicConfig;