import React, {useState} from "react";
import './professionalItem.scss';
import { useHistory } from 'react-router-dom';
import {createAnalyticsEvent} from '../../../firebase/firebase';
import { getLogicalName } from '../../../utils';

import UserLogo from '../../../assests/user-avatar.svg';
import getIcon from "../../../config/iconToCategoryMap";

export default function ProfessionalItem(props) {

    const { professional } = props;
    const {id, businessName, ownerName, category, logoUrl } = professional;

    const [isLogoLoaded, setIsLogoLoaded] = useState(!logoUrl ? true : false);

    const logicName = getLogicalName();


    const history = useHistory();

    const onProfessionalClicked = ()=>{
        history.push(`/${logicName}/${id}`);
        createAnalyticsEvent({eventName: 'profession-clicked', eventParams: {id, businessName, ownerName, category}});
    }

    const onLogoLoad = ()=>{
        setIsLogoLoaded(true);
    }

    return (
            <div className={'professional-container'} key={`professional-container-${id}`} onClick={onProfessionalClicked}>
                <div className={'professional-container-upper'}>
                    {logoUrl ? <img className={`user-logo-cloud ${isLogoLoaded ? '' : 'loading'}`} src={logoUrl} onLoad={onLogoLoad}/> :
                        <img src={UserLogo} className={'user-logo-svg'}/>}
                    <div className={'professional-name'} key={`businessName-${id}`}>{businessName}</div>
                </div>
                <div className={'owner-name'} key={`ownerName-${id}`}>{ownerName}</div>
                <div className={'business-category-container'}>
                    {getIcon(category)}
                    <span>{category}</span>
                </div>
            </div>

    );
}