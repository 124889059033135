import React, {useEffect, useState} from 'react';
import './ChooseTenant.scss';
import dynamicConfig from '../../config/dynamicConfig';
import {useHistory} from "react-router-dom";

const ChooseTenant = (props)=>{

    const history = useHistory();

    return <div className={'choose-tenant-container'}>
        {Object.keys(dynamicConfig).map(logicName=><div className={'tenant-container'} onClick={()=>window.location.href = `${window.location.origin}/${logicName}`}>
            <div>{dynamicConfig[logicName].title}</div>
            <img className={'logo'} src={dynamicConfig[logicName].squareIcon} alt={''}/>
            <img className={'logo'} src={dynamicConfig[logicName].wideIcon} alt={''}/>
        </div>)}
    </div>
}

export default ChooseTenant;