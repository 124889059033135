import config from '../config/config';
import axios from 'axios';

const localStorage = window.localStorage;

class SingletoneStoreClass {
    constructor() {
       this.professionals = [];
       this.categories = [];
       this.loading = false;
    }

    _parseData(data) {
        console.info('start parsing data...');
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html")
        const table = doc.querySelector("table");
        let columnsNamesMap = {};
        let results = [];
        let counter = 0;
        for(let i=1; i< table.rows[1].cells.length;i++){
            const columnsName = table.rows[1].cells[i].textContent;
            if(columnsName){
                counter ++;
                columnsNamesMap[counter] = columnsName;
            }
        }

        // console.info('columns names are: ',Object.values(columnsNamesMap));
        // console.info('table: ',table);

        for(let i=2; i< table.rows.length;i++){
            const obj = {};
            for(let j=1; j<counter + 1; j++){
                obj[columnsNamesMap[j]] = table.rows[i].cells[j].textContent;
            }


            if(obj.id && !obj.id.includes(" ")) results.push(obj); //show only object with id!
        }
        console.log(`successfully parsed data (${results.length} items)`);
        return results;

    }

    async getData(){
        this.loading = true;
        try {
            const result = await axios.get(config.googleSheetsUrl);
            this.professionals = this._parseData(result.data);
            console.log("---this.professionals=",this.professionals);
            const categoriesObj = {};
            this.professionals.forEach(professional=>{
                if(!professional.category) return;
                if(!categoriesObj[professional.category]) categoriesObj[professional.category] = true;
            })
            this.categories = Object.keys(categoriesObj);
            console.info('the categories are: ',this.categories);
            this.loading = false;
        }catch (e){
            throw e;
        }finally {
            this.loading = false;
        }
    }


    static getInstance() {
        if (!SingletoneStoreClass.instance) {
            SingletoneStoreClass.instance = SingletoneStoreClass.createInstance();
        }
        return SingletoneStoreClass.instance;
    }

    static getFromLocalStorage(key) {
        try {
            const value = localStorage.getItem(key);
            return JSON.parse(value);
        } catch (e) {
            console.error('got error on getFromLocalStorage: ', e);
            return null;
        }
    }


    static instance = null;

    static createInstance() {
        let object = new SingletoneStoreClass();
        return object;
    }

}

export default SingletoneStoreClass;